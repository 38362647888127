import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { socket } from '../../socket'
import { Button } from '../Items/Button'
import { getPictureList, getSignedUrl } from '../../server'

const Gallery = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`

const ArrowButton = styled(Button)`
  width: 3em;
  height: 3em;
  margin: 2vw;
  flex-shrink: 0;
  background-color: rgb(0 79 255 / 50%);
  border-radius: 50%;
  aspect-ratio: 1;
  ${(props) => props.disabled && `background-color: rgb(169 169 169 / 50%)`}
`

const VideoPlayer = styled.video`
  min-width: 0;
  width: 100%;
  border-radius: 10px;
`

const ImagePlayer = styled.img`
  min-width: 0;
  width: 100%;
  border-radius: 10px;
`

/**
 * Converts an image name string into a formatted date string.
 *
 * The image name is expected to be in the format: "YYYY_MM_DD-HH_MM_SS.ext".
 * The function extracts the date and time parts, converts them into a Date object,
 * and returns a locale string in 'en-GB' format with the time zone name.
 *
 * @param {string} imageName - The name of the image file (e.g., "2023_06_19-14_35_10.jpg").
 * @returns {string} - The formatted date string (e.g., "19/06/2023, 15:35:10 BST").
 */
function getDateString(imageName) {
  try {
    const [year, month, day] = imageName.split('.')[0].split('-')[0].split('_')
    const [hour, minute, second] = imageName.split('.')[0].split('-')[1].split('_')
    const date = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}Z`)
    return date.toLocaleString('en-GB', { timeZoneName: 'short'})
  } catch (e) {
    console.error('getDateString error', e)
    return imageName
  }
}

export default function Camera({ wec }) {
  const [pictureList, setPictureList] = useState([])
  const [pictureIndex, setPictureIndex] = useState(0)
  const [videoDuration, setVideoDuration] = useState(30)
  const [takingPicture, setTakingPicture] = useState(false)
  const [takingVideo, setTakingVideo] = useState(false)
  const [error, setError] = useState('')
  const [signedUrl, setSignedUrl] = useState(null)

  useEffect(() => {
    (async function() {
      const pictureList = await getPictureList()
      setPictureList(pictureList)
    })()
  }, [])

  useEffect(() => {
    if (pictureList && pictureList[pictureIndex]) {
      (async function () {
        const signedUrl = await getSignedUrl(pictureList[pictureIndex])
        setSignedUrl(signedUrl)
      })()
    }
  }, [pictureList, pictureIndex])

  useEffect(() => {
    function onNewPicture(data) {
      console.log('onNewPicture:', data)
      setPictureList(oldList => [data, ...oldList])
      setPictureIndex(index => index > 0 ? index + 1 : index)
    }
    socket.on('new_picture', onNewPicture)
    return () => {
      socket.off('new_picture', onNewPicture)
    }
  }, [])

  if (!pictureList || pictureList.length === 0)
    return <div/>

  function onTakePicture() {
    setTakingPicture(true)
    socket.emit('take_picture', { sid: wec.sid }, (val) => {
      console.log('Take picture ack:', val)
      setTakingPicture(false)
      setError(val === 'OK' ? '' : val)
    })
  }

  function onTakeVideo() {
    setTakingVideo(true)
    socket.emit('take_video', { sid: wec.sid, duration: videoDuration }, (val) => {
      console.log('Take video ack:', val)
      setTakingVideo(false)
      setError(val === 'OK' ? '' : val)
    })
  }

  return (
    <div style={{width: '100%'}}>
      {signedUrl &&
        <Gallery>
          <ArrowButton
            disabled={pictureIndex <= 0}
            onClick={() => setPictureIndex(pictureIndex - 1)}
          >⬅</ArrowButton>
          {signedUrl.includes('.mp4')
            ? <VideoPlayer controls autoPlay src={signedUrl}/>
            : <ImagePlayer src={signedUrl}/>
          }
          <ArrowButton
            disabled={pictureIndex >= pictureList.length - 1}
            onClick={() => setPictureIndex(pictureIndex + 1)}
          >➡</ArrowButton>
        </Gallery>
      }
      <p>{getDateString(pictureList[pictureIndex])}</p>
      <div style={{marginTop: '32px', gap: '1em', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Button onClick={onTakePicture} disabled={takingPicture}>Take picture</Button>
        <Button onClick={onTakeVideo} disabled={takingVideo}>Take video</Button>
        <input type='number' style={{width: '64px'}} value={videoDuration} onChange={e => setVideoDuration(e.target.value)}/> seconds
      </div>
      {error && <p style={{color: 'red'}}>{error}</p>}
    </div>
  )
}