import { useState } from 'react'
import styled from 'styled-components'
import { patchWec } from '../../server'
import { socket } from '../../socket'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px;
  justify-content: center;
`

export default function Settings({ wec, updateWec }) {
  const [checkboxDisabled, setCheckboxDisabled] = useState(false)
  const [slider, setSlider] = useState(0)
  const [sliderLabel, setSliderLabel] = useState('1V')

  function onSendMotorControl(value) {
    setSlider(value)
    socket.emit('motor_control', { value, sid: wec.sid }, (val) => {
      console.log('Motor control ack:', val)
      setSliderLabel(val === 'OK' ? `${value}V` : '-V')
    })
  }

  if (!wec)
    return <div />

  return (
    <div style={{ width: '100%' }}>
      <Container>
        Should be turned on
        <input
          type='checkbox'
          style={{ width: '2rem' }}
          checked={wec?.should_be_turned_on}
          disabled={checkboxDisabled}
          onChange={async e => {
            try {
              setCheckboxDisabled(true)
              const updatedWec = await patchWec(wec.id, { should_be_turned_on: e.target.checked })
              updateWec(updatedWec)
            }
            catch (e) {
              console.error("Error patching wec", e)
            }
            setCheckboxDisabled(false)
          }}
        />
      </Container>
      <br />
      <br />
      <div style={{ display: 'flex', justifyContent: 'center', gap: '8px', flexWrap: 'wrap' }}>
        Motor control
        <input type="range" min="0" max="3.3" step="0.1" value={slider} onChange={e => onSendMotorControl(e.target.value)}></input>
        {sliderLabel}
      </div>
    </div>
  )
}
