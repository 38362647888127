import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components'
import Camera from './components/Pages/Camera'
import Rotations from './components/Pages/Rotations';
import Power from './components/Pages/Power';
import Settings from './components/Pages/Settings';
import { socket } from './socket';

const Title = styled.h1`
  margin: 0
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 3vmax;
  padding: 17vh 0;
  max-width: 1200px;
  margin: 0 auto;
`

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const Tab = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  color: white;
  width: 8em;
  height: 3em;
  border-radius: 99px;
  font-size: 1em;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  ${(props) => props.selected && `
    background-color: rgb(0 79 255);
    cursor: default;
  `}
`

export default function Home({connected, wec, updateWec}) {
  const [powerData, setPowerData] = useState(null)
  const [activeTab, setActiveTab] = useState('Pictures')

  const title = useMemo(() => {
    if (connected === true)
      return wec?.['online'] ? 'WEC is online' : 'WEC is offline'
    else if (connected === false)
      return 'No connection'
    else
      return 'Connecting...'
  }, [connected, wec])

  useEffect(() => {
    function onSensorData({type, data}) {
      console.log('onSensorData', data)
      if (type === 'power')
        setPowerData(data?.[0])
    }

    socket.on('sensor_data', onSensorData)
    return () => {
      socket.off('sensor_data', onSensorData)
    }
  })

  return (
    <Container>
      <div>
        <Title>{title}</Title>
        {powerData && <p>Battery: {powerData?.['battery_percentage']}%<br/>{powerData?.['power_input_status']}</p>}
        {!wec?.['online'] && <p>Last online: {new Date(wec?.['last_online']).toLocaleString()}</p>}
      </div>
      <Tabs>
        <Tab selected={activeTab === 'Pictures'} onClick={() => setActiveTab('Pictures')}>Pictures</Tab>
        <Tab selected={activeTab === 'Rotations'} onClick={() => setActiveTab('Rotations')}>Rotations</Tab>
        <Tab selected={activeTab === 'Power'} onClick={() => setActiveTab('Power')}>Power</Tab>
        <Tab selected={activeTab === 'Settings'} onClick={() => setActiveTab('Settings')}>Settings</Tab>
      </Tabs>
      {activeTab === 'Pictures' && <Camera wec={wec} />}
      {activeTab === 'Rotations' && <Rotations />}
      {activeTab === 'Power' && <Power />}
      {activeTab === 'Settings' && <Settings wec={wec} updateWec={updateWec} />}
    </Container>
  )
}